import axios from 'axios';
import { Link } from 'gatsby';
import React, { useState } from 'react'
import Layout from '../components/Layout'
import { Seo } from '../components/seo'

export default function Contact() {
    const [show, setShow] = useState(false);
    const [disable, setDisable] = useState(false);
    const [spinner, setSpinner] = useState(false);

    let startData = {
        name: "",
        c_name: "",
        email: "",
        phone: "",
        emessage: "",
    }

    const [formData, setFormData] = useState(startData)

    const handleInput = (event) => {
        const latestData = { ...formData }
        latestData[event.target.id] = event.target.value
        setFormData(latestData);
    }

    const baseURL = "https://app.todayfilings.com/api/contact-mail";
    // const baseURL = "http://127.0.0.1:8000/api/contact-mail";
    const SubmitForm = (event) => {
      event.preventDefault();
      setSpinner(true);
      setDisable(true);
      const data = new FormData(event.target);
      let axiosConfig = {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:8000",
          "Content-Type": "application/json",
        },
      };
      axios
        .post(baseURL, data, axiosConfig)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              setDisable(false);
              setSpinner(false);
            }, 3000);
            setFormData(startData);
          } else {
            alert("no");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <>
        <Seo
          title="TODAYFILINGS Contact"
          keywords="Keywords"
          description="Description"
          name="TODAYFILINGS"
          type="Website"
          author="TODAYFILINGS"
        />
        <Layout>
          <section className="breadcrumbs">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="content">
                    <h1
                      className="aos-init text-center"
                      data-aos="fade-down"
                      data-aos-offset="500"
                    >
                      {" "}
                      Contact Us
                    </h1>
                    <p
                      className="aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-offset="00"
                    >
                      {" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <main id="main">
            <section className="mt-5">
              <div
                className="section-header mt-5"
                data-aos="fade-up"
                data-aos-offset="100"
              >
                <h2>Get in Touch!</h2>
                <div className="bar"></div>
              </div>
              <p className="d-none d-sm-block mt-3 text-center">
                Connect us and our knowledgeable staff will assist you if you
                have any questions about our online tax filling and tax
                consultancy services.
              </p>
            </section>
            <section id="contact" className="contact-area ptb_100 ">
              <div className="container mt-5">
                <div className="row justify-content-between align-items-center ">
                  <div className="col-12 col-lg-6 pt-4 pt-lg-0 ">
                    <div className="contact-box text-center">
                      <form
                        id="contact-form"
                        method="POST"
                        onSubmit={SubmitForm}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group" data-aos="fade-up">
                              <input
                                type="text"
                                className="form-control border-left1"
                                id="name"
                                name="name"
                                placeholder="Name"
                                required="required"
                                autoComplete="off"
                                value={formData.name}
                                onChange={handleInput}
                              />
                            </div>
                            <div className="form-group" data-aos="fade-up">
                              <input
                                type="text"
                                className="form-control border-left2"
                                id="c_name"
                                name="c_name"
                                placeholder="Company Name"
                                required="required"
                                autoComplete="off"
                                value={formData.c_name}
                                onChange={handleInput}
                              />
                            </div>
                            <div className="form-group" data-aos="fade-up">
                              <input
                                type="email"
                                className="form-control border-left1"
                                id="email"
                                name="email"
                                placeholder="Email"
                                required="required"
                                autoComplete="off"
                                value={formData.email}
                                onChange={handleInput}
                              />
                            </div>
                            <div className="form-group" data-aos="fade-up">
                              <input
                                type="phone"
                                className="form-control border-left2"
                                name="phone"
                                id="phone"
                                placeholder="Phone"
                                required="required"
                                autoComplete="off"
                                value={formData.phone}
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                          <div className="col-12" data-aos="fade-up">
                            <div className="form-group">
                              <textarea
                                className="form-control border-left1"
                                name="emessage"
                                id="emessage"
                                placeholder="Message"
                                required="required"
                                autoComplete="off"
                                value={formData.emessage}
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                          <div className="col-12" data-aos="fade-up">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              disabled={disable ? "disable" : ""}
                            >
                              {spinner ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <span
                                  className="text-white"
                                  style={{ marginRight: 10 }}
                                >
                                  <i className="bi bi-send"></i>
                                </span>
                              )}
                              Send Message
                            </button>
                          </div>
                          {show ? (
                            <p
                              id="success-text"
                              className="success-text text-center text-primary"
                            >
                              Enquiry Submit Successfully
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-12 col-lg-5">
                    <div className="contact-us">
                      <ul>
                        <li
                          className="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3"
                          data-aos="fade-up"
                        >
                          <span>
                            <i className="bi bi-phone-fill"></i>
                          </span>
                          <Link to="tel:9585055661" className="d-block my-2">
                            <h3>+91 9585 055 661</h3>
                          </Link>
                        </li>
                        <li
                          className="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3"
                          data-aos="fade-up"
                        >
                          <span>
                            <i className="bi bi-envelope-fill"></i>
                          </span>
                          <Link className="d-none d-sm-block my-2">
                            <h3>support@todayfilings.com</h3>
                          </Link>
                          <Link className="d-block d-sm-none my-2">
                            <h3>support@todayfilings.com</h3>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="contact-area ptb_100 ">
              <div className="container">
                <div className="row ">
                  <div className="col-md-6">
                    <div class="card">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="text-center mb-4">
                              Corporate Office
                            </h3>
                            <div class="card">
                              <div
                                className="text-center"
                                style={{ fontSize: 40 }}
                              >
                                <i class="bi bi-house-door-fill text-danger"></i>
                              </div>
                              <div class="card-body">
                                <p class="card-text text-center">
                                  No B1 Vishnu castle MARUTHI nagar 3rd cross
                                  dharga
                                  <br />
                                  Hosur -635126- Tamilnadu{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <Link
                              to="tel:9585055661"
                              style={{ pointer: "cursor" }}
                            >
                              <div class="card">
                                <div
                                  className="text-center"
                                  style={{ fontSize: 40 }}
                                >
                                  <i class="bi bi-telephone-fill text-danger"></i>
                                </div>
                                <div class="card-body text-center">
                                  <p class="card-text ">9585055661</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-7">
                            <Link to="mailto:support@todayfilings.com">
                              <div class="card">
                                <div
                                  className="text-center"
                                  style={{ fontSize: 40 }}
                                >
                                  <i class="bi bi-envelope-open-fill text-danger"></i>
                                </div>
                                <div class="card-body text-center">
                                  <p class="card-text">
                                    support@todayfilings.com
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <iframe
                            title="map"
                            className="card-img-bottom"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.5159358119195!2d77.81425707406434!3d12.744970719808522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae714d656bf309%3A0xbc2d207d0f0b33e3!2sVishnu%20Castle!5e0!3m2!1sen!2sin!4v1686029351663!5m2!1sen!2sin"
                            width="100"
                            height="100"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="text-center mb-4">
                              Branch office Bangalore
                            </h3>
                            <div class="card">
                              <div
                                className="text-center"
                                style={{ fontSize: 40 }}
                              >
                                <i class="bi bi-house-door-fill text-danger"></i>
                              </div>
                              <div class="card-body">
                                <p class="card-text text-center">
                                  No 40 Ground floor, 14th cross , 12th main,
                                  sector 6<br /> hsr layout Bangalore -560102
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <Link
                              to="tel:9585540037"
                              style={{ pointer: "cursor" }}
                            >
                              <div class="card">
                                <div
                                  className="text-center"
                                  style={{ fontSize: 40 }}
                                >
                                  <i class="bi bi-telephone-fill text-danger"></i>
                                </div>

                                <div class="card-body text-center">
                                  <p class="card-text ">9585540037</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-7">
                            <Link
                              to="mailto:hsrlayout@todayfilings.com"
                              style={{ pointer: "cursor" }}
                            >
                              <div class="card">
                                <div
                                  className="text-center"
                                  style={{ fontSize: 40 }}
                                >
                                  <i class="bi bi-envelope-open-fill text-danger"></i>
                                </div>
                                <div class="card-body text-center">
                                  <p class="card-text">
                                    hsrlayout@todayfilings.com
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <iframe
                            title="map"
                            className="card-img-bottom"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62213.090220181475!2d77.56084588124953!3d12.951483043223414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ce4ccfcac5%3A0xcfd6c6f688e8f992!2sHSR%20Layout%2C%20Sector-6%2C%20Bangalore%2C%20Karnataka%2C%20560102!5e0!3m2!1sen!2sin!4v1686030776407!5m2!1sen!2sin"
                            width="100"
                            height="100"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </Layout>
      </>
    );
}
